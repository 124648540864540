<template>
  <v-row class="mt-15 pt-15">
    <v-col>
      <v-form ref="form" @submit.prevent="change" light v-if="enviado">
        <v-row justify="center">
          <v-col cols="12" md="6" xl="6">
            <v-text-field
              name="password"
              label="Contraseña actual"
              :append-icon="actual ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (actual = !actual)"
              :type="actual ? 'password' : 'text'"
              :rules="contrasenaRules"
              v-model="current"
              light
            ></v-text-field>
            <v-text-field
              name="password"
              label="Contraseña nueva"
              :append-icon="nueva1 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (nueva1 = !nueva1)"
              :type="nueva1 ? 'password' : 'text'"
              :rules="contrasenaRules"
              v-model="newpass"
              light
            ></v-text-field>
            <v-text-field
              name="password"
              label="Contraseña repetir nueva"
              :append-icon="nueva2 ? 'mdi-eye' : 'mdi-eye-off'"
              @click:append="() => (nueva2 = !nueva2)"
              :type="nueva2 ? 'password' : 'text'"
              :rules="contrasenaRules2"
              v-model="newpass2"
              light
            ></v-text-field>
            <v-btn
              light
              block
              class="niu-background-principal-1 mr-4"
              type="submit"
              >Cambiar Contraseña</v-btn
            >
          </v-col>
        </v-row>
      </v-form>
      <div v-else>
        <v-btn to="/explora">Ir a Explora</v-btn> o
        <v-btn to="/explora-premium">Ir a Explora Premium</v-btn>
      </div>
    </v-col>
  </v-row>
</template>
<script>
// import webapi from "../webapi/webapi";
export default {
  data () {
    return {
      actual: true,
      nueva1: true,
      nueva2: true,
      contrasenaRules: [
        v => !!v || 'Contraseña requerida',
        v => (v && v.length <= 16) || 'Excede el límite de caracteres',
        v =>
          /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/.test(v) ||
          'La contraseña debe tener al entre 8 y 16 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.'
      ],
      current: '',
      newpass: '',
      newpass2: '',
      enviado: true
    }
  },
  mounted () {
    // eslint-disable-next-line no-console
    // console.log(this.$refs.form.$el.style.display='none')
  },
  computed: {
    contrasenaRules2 () {
      return [
        v => !!v || 'Contraseña requerida',
        v => (v && v.length <= 20) || 'Excéde el límite de caracteres 2',
        v => v == this.newpass || 'Las contraseñas no coinciden',
        v =>
          /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,20}$/.test(v) ||
          'La contraseña debe tener al entre 8 y 20 caracteres, al menos un dígito, al menos una minúscula y al menos una mayúscula.'
      ]
    }
  },
  methods: {
    utf8_to_b64 (str) {
      return window.btoa(unescape(encodeURIComponent(str)))
    },
    change () {
      let op = {
        username: this.$storeNiu.getTextOrInt('user'),
        password: this.$md5(this.$md5(this.utf8_to_b64(this.current))),
        newpassword: this.$md5(this.$md5(this.utf8_to_b64(this.newpass)))
      }
      if (this.$refs.form.validate()) {
        try {
          this.$http
            .post('/users/Password', op)
            .then(result => {
              // this.$store.commit('noco',result);
              if (result) {
                // alert("Exito");

                this.$store.commit('alert', {
                  type: 'success',
                  message: 'Cambio realizado',
                  snackbar: true
                })
                this.current = ''
                this.newpass = ''
                this.newpass2 = ''
                this.enviado = false
              } else {
                // this.$refs.form.reset()

                this.$store.commit('alert', {
                  type: 'error',
                  message: 'Error al cambiar la contraseña, revise sus datos',
                  snackbar: true
                })
              }
            })
            //eslint-disable-next-line
            .catch(e => {
              this.$store.commit('alert', {
                type: 'error',
                message: 'Error al cambiar la contraseña, revise sus datos',
                snackbar: true
              })
            })
          //eslint-disable-next-line
        } catch (error) {
          this.$store.commit('alert', {
            type: 'error',
            message: 'Error al cambiar la contraseña, revise sus datos',
            snackbar: true
          })
        }
      } else {
        this.$store.commit('alert', {
          type: 'error',
          message: 'Error al cambiar la contraseña, revise sus datos',
          snackbar: true
        })
      }
    }
  }
}
</script>
